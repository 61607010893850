import React, {useState} from 'react';

interface CopyLinkProps {
    link: string;
}

const CopyLink: React.FC<CopyLinkProps> = ({link}) => {
    const [copied, setCopied] = useState<boolean>(false);

    const copyToClipboard = () => {
        // Use Clipboard API if available
        if (navigator.clipboard) {
            navigator.clipboard.writeText(link).then(() => {
                handleSuccess();
            }).catch(() => {
                handleFallback();
            });
        } else {
            handleFallback();
        }
    };

    const handleSuccess = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };

    const handleFallback = () => {
        // Fallback using execCommand
        const textArea = document.createElement("textarea");
        textArea.value = link;
        document.body.appendChild(textArea);
        textArea.select();
        try {
            document.execCommand('copy');
            handleSuccess();
        } catch (err) {
            console.error('Fallback method failed to copy:', err);
        }
        document.body.removeChild(textArea);
    };


    return (
        <><h4>Payment link Generated: </h4>
        <div className="d-flex flex-column justify-content-center align-items-center PaymentLinkForm">
            <input
                type="text"
                readOnly
                value={link}
                className="form-control"
                style={{ maxWidth: '100%', marginBottom: 20, marginTop: 20 }} />
            <button className="btn btn-primary ml-2" onClick={copyToClipboard}>
            <svg style={{ marginRight: 10 }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1_211_23" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5 1H15V11H12V5C12 4.44772 11.5523 4 11 4H5V1ZM4 4V1C4 0.447715 4.44772 0 5 0H15C15.5523 0 16 0.447715 16 1V11C16 11.5523 15.5523 12 15 12H12V15C12 15.5523 11.5523 16 11 16H1C0.447715 16 0 15.5523 0 15V5C0 4.44771 0.447715 4 1 4H4Z"/>
</mask>
<path d="M15 1H16V0H15V1ZM5 1V0H4V1H5ZM15 11V12H16V11H15ZM12 11H11V12H12V11ZM5 4H4V5H5V4ZM4 4V5H5V4H4ZM12 12V11H11V12H12ZM15 0H5V2H15V0ZM16 11V1H14V11H16ZM12 12H15V10H12V12ZM11 5V11H13V5H11ZM11 5H13C13 3.89543 12.1046 3 11 3V5ZM5 5H11V3H5V5ZM4 1V4H6V1H4ZM3 1V4H5V1H3ZM5 -1C3.89543 -1 3 -0.10457 3 1H5V1V-1ZM15 -1H5V1H15V-1ZM17 1C17 -0.104569 16.1046 -1 15 -1V1H17ZM17 11V1H15V11H17ZM15 13C16.1046 13 17 12.1046 17 11H15V13ZM12 13H15V11H12V13ZM11 12V15H13V12H11ZM11 15V17C12.1046 17 13 16.1046 13 15H11ZM11 15H1V17H11V15ZM1 15H1H-1C-1 16.1046 -0.10457 17 1 17V15ZM1 15V5H-1V15H1ZM1 5V3C-0.104569 3 -1 3.89543 -1 5H1ZM1 5H4V3H1V5Z" fill="white" mask="url(#path-1-inside-1_211_23)"/>
</svg>
  {copied ? 'Copied!' : 'Copy'}
            </button>
        </div></>
    );
};

export default CopyLink;
