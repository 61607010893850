import React from "react";
import MollieLogo from "../Mollie.svg"
import '../css/header.css';

const HeaderLogo = <img src={MollieLogo} className="Header-logo" alt="logo"/>;
const Header: React.FC = () => {

    return <nav>{HeaderLogo}</nav>;
}



export default Header;
