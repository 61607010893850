import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axiosClient from "../AxiosConfig";

interface Profile {
    profileId: string;
    name: string;
    status: string;
}

interface IProfileSelectInputProps {
    profileId: string | null;
    setProfileId: (profileId: string | null) => void;
    setHasMollieAccount: (hasMollieAccount: boolean) => void;
}

function ProfileSelectInput({ profileId, setProfileId, setHasMollieAccount }: IProfileSelectInputProps) {
    const [profiles, setProfiles] = useState<Profile[]>([]);

    useEffect(() => {
        async function fetchProfiles() {
            try {
                const response = await axiosClient.get(`profiles`, { withCredentials: true });
                setProfiles(response.data.map((profile: any) => ({
                    profileId: profile.id,
                    name: profile.name,
                    status: profile.status
                })));
            } catch (e) {
                console.error("Error fetching profiles:", e);
                alert('Something went wrong while fetching website profiles!');
                window.location.reload();
            }
        }

        fetchProfiles();
    }, []);

    return (
        <>
        <div className="form__field">
            <Form.Label htmlFor="profile">Profile</Form.Label>
            <Form.Control
                    as="select"
                    id="profile"
                    value={profileId || ""}
                    onChange={(e) => setProfileId(e.target.value || null)}
                >
                    <option value="">Select a profile</option>
                    {profiles.map(profile => (
                        <option key={profile.profileId} value={profile.profileId}>
                            {profile.name} : {profile.status}
                        </option>
                    ))}
                </Form.Control>
        </div>
        </>
    );
}

export default ProfileSelectInput;
