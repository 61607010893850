import { Button } from "react-bootstrap";
import "../css/buttons.css";
import "../css/typography.css";
import Footer from "../components/Footer";

interface ILayoutProps {
  children: React.ReactNode;
  logout: () => void;
}

function Layout(layoutProps: ILayoutProps) {
  return (
    <>
      {layoutProps.children}
      <div className="d-flex justify-content-center">
        <Button
          className="logout-btn"
          onClick={layoutProps.logout}
          variant="text text-white"
          style={{ padding: "10px 20px" }}
        >
          Log out
        </Button>
      </div>
      <Footer />
    </>
  );
}

export default Layout;
