import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Callback from "./pages/Callback";
import MollieOAuth from "./pages/MollieOAuth";
import {JwtUtil} from "./util/jwt-util";
import Layout from "./layout/Layout";
import CardIFrame from "./pages/CardIFrame";
import CopyLinkIframe from "./pages/CopyLinkIframe";
import PaymentRedirectHandler from "./pages/PaymentRedirectHandler";
import axiosClient from "./AxiosConfig";
import {RefundIFrame} from "./pages/RefundIFrame";


function App() {
    const [isAuth, setIsAuth] = useState(JwtUtil.checkJWT());
    const [hasMollieAccount, setHasMollieAccount] = useState(false);


    useEffect(() => {
        setIsAuth(JwtUtil.checkJWT());

        isAuth ? checkMollieAccount() : setHasMollieAccount(false);

        async function checkMollieAccount() {
            try {
                const response = await axiosClient.get(`/oauth/mollie/`, {
                    withCredentials: true,
                });
                if (response.status === 200) {
                    setHasMollieAccount(true);
                } else {
                    setHasMollieAccount(false);
                }
            } catch (error) {
                console.log('Error', error);
                setHasMollieAccount(false);
            }
        }
    }, [isAuth]);

    function logout() {
        console.log('Logout');
        JwtUtil.removeJWT();
        setIsAuth(false);
    }

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path={'/redirect/payment-links/:id'} element={<PaymentRedirectHandler/>}/>
                    <Route path={'/payment-links/:id'} element={<PaymentRedirectHandler/>}/>
                    <Route path={'/payment-links/:id/refund'} element={<RefundIFrame/>}/>
                    <Route path={'/refund-success'} element={<h4>Refund successful</h4>}/>
                    <Route path={'/hubspot/card/iframe'} element={<CardIFrame/>}/>
                    <Route path={'/payment-links/:id/iframe'} element={<CopyLinkIframe/>}/>
                </Routes>
                {!isAuth ? (<Routes>
                        <Route path={'/'} element={<Home/>}/>
                        <Route path="/oauth/hubspot/callback" element={<Callback provider={'hubspot'}/>}/>
                    </Routes>
                ) : (
                    <Layout logout={logout}>
                        <Routes>
                            <Route path={'/'} element={<MollieOAuth hasMollieAccount={hasMollieAccount}
                                                                    setHasMollieAccount={setHasMollieAccount}/>}/>
                            <Route path={'/oauth/mollie/callback'} element={<Callback provider={'mollie'}/>}/>
                        </Routes>
                    </Layout>

                )}
            </BrowserRouter>
        </div>
    );
}

export default App;
