import React from "react";
import { Button } from "react-bootstrap"; // Assuming you're using `react-bootstrap`
import { Scrollbars, positionValues } from 'react-custom-scrollbars-2';
import '../css/DPA.css'
import LogoCircles from "./LogoCircles";



interface DPAModalProps {
  show: boolean;
  onAccept: () => void;
}

// function handleScroll(scHeight: { getScrollHeight: number; } | undefined){
//     const gradient = document.querySelector('.dpa__actions:before') as HTMLElement | null;
//     if 
//     const controlLine = scHeight?.getScrollHeight * 0.8
// }

const DPAModal: React.FC<DPAModalProps> = ({ show, onAccept }) => {

    function onScrollHandler(values: positionValues) {
        const gradEl = document.querySelector('.gradient__spacer') as HTMLElement | null
        const scHeight = values.scrollHeight;
        const clHeight = values.clientHeight;
        var scTop = values.scrollTop
        var scBot = scTop + clHeight
        var opacity = 1 - ((scBot - (scHeight * .9)) / (scHeight * 0.1))
        if(scBot > (scHeight * .9)){
            if (gradEl){
            gradEl.style.cssText = `opacity: ${opacity};`
            }
        } else {
            if (gradEl){
                gradEl.style.cssText = ""
            }
        }
    }
    
  return (
    <>
      <h1>Data Processing Agreement</h1>
      <div className="dpa__wrapper">
        <Scrollbars className="scrollbars-custom" style={{ height: 542}}
        onScrollFrame={onScrollHandler}
        >
        <div className="dpa__content">
          <h2>Wiljekoffie B.V.</h2>

          <p>
            <strong>This Data Processing Agreement (“Agreement“)</strong> forms
            part of the Contract for Services (“Principal Agreement“) between:
          </p>

          <h4>Company (Data Controller):</h4>
          <ul>
            <li>Name: Wiljekoffie B.V.</li>
            <li>
              Address: Haaksbergerstraat 67, 7554PA Hengelo, The Netherlands
            </li>
            <li>Legal Entity: Private Limited Company</li>
          </ul>

          <h4>Data Processor:</h4>
          <p>Client that agrees to install this app</p>

          <h4>WHEREAS</h4>
          <ol type="A">
            <li>Wiljekoffie B.V. acts as a Data Controller.</li>
            <li>
              Wiljekoffie B.V. wishes to subcontract certain Services, which
              imply the processing of personal data, to the Client that agrees
              to install this app.
            </li>
            <li>
              The Parties seek to implement a data processing agreement that
              complies with the requirements of the current legal framework in
              relation to data processing and with the Regulation (EU) 2016/679
              of the European Parliament and of the Council of 27 April 2016 on
              the protection of natural persons with regard to the processing of
              personal data and on the free movement of such data, and repealing
              Directive 95/46/EC (General Data Protection Regulation).
            </li>
            <li>The Parties wish to lay down their rights and obligations.</li>
          </ol>
          <p>
            <strong>IT IS AGREED AS FOLLOWS:</strong>
          </p>
          <ol>
            <ol type={"1"}>
              <li>
                <strong>Definitions and Interpretation</strong>
              </li>
              <ul>
                <li>
                  {" "}
                  1.1 Unless otherwise defined herein, capitalized terms and
                  expressions used in this Agreement shall have the following
                  meaning:
                </li>
                <li>
                  {" "}
                  1.1.1 “Agreement” means this Data Processing Agreement and all
                  Schedules;
                </li>
                <li>
                  {" "}
                  1.1.2 “Company Personal Data” means any Personal Data
                  Processed by a Contracted Processor on behalf of Wiljekoffie
                  B.V. pursuant to or in connection with the Principal
                  Agreement;
                </li>
                <li> 1.1.3 “Contracted Processor” means a Subprocessor;</li>
                <li>
                  {" "}
                  1.1.4 “Data Protection Laws” means EU Data Protection Laws
                  and, to the extent applicable, the data protection or privacy
                  laws of any other country;
                </li>
                <li> 1.1.5 “EEA” means the European Economic Area;</li>
                <li>
                  {" "}
                  1.1.6 “EU Data Protection Laws” means EU Directive 95/46/EC,
                  as transposed into domestic legislation of each Member State
                  and as amended, replaced or superseded from time to time,
                  including by the GDPR and laws implementing or supplementing
                  the GDPR;
                </li>
                <li>
                  {" "}
                  1.1.7 “GDPR” means EU General Data Protection Regulation
                  2016/679;
                </li>
                <li> 1.1.8 “Data Transfer” means:</li>
                <li>
                  {" "}
                  1.1.8.1 a transfer of Company Personal Data from Wiljekoffie
                  B.V. to a Contracted Processor; or
                </li>
                <li>
                  {" "}
                  1.1.8.2 an onward transfer of Company Personal Data from a
                  Contracted Processor to a Subcontracted Processor, or between
                  two establishments of a Contracted Processor, in each case,
                  where such transfer would be prohibited by Data Protection
                  Laws (or by the terms of data transfer agreements put in place
                  to address the data transfer restrictions of Data Protection
                  Laws);
                </li>
                <li>
                  {" "}
                  1.1.9 “Services” means the services provided by Wiljekoffie
                  B.V., including: This integration's primary focus is to allow
                  for creation of Mollie checkout payment links from HubSpot
                  deal section & allowing for this link to be copied and sent to
                  the customer. By providing this functionality the B2B
                  customers of HubSpot can offer online payments to their
                  consumers outside of a typical eCommerce platform set up. Once
                  the integration is connected to the Mollie account, by
                  utilising HubSpot’s API, a section of Mollie payments will
                  appear as a Custom Card in the Deal and Contact section.
                </li>
                <li>
                  {" "}
                  1.1.10 “Subprocessor” means any person appointed by or on
                  behalf of Processor to process Personal Data on behalf of
                  Wiljekoffie B.V. in connection with the Agreement.
                </li>
                <li>
                  {" "}
                  1.2 The terms, “Commission”, “Controller”, “Data Subject”,
                  “Member State”, “Personal Data”, “Personal Data Breach”,
                  “Processing” and “Supervisory Authority” shall have the same
                  meaning as in the GDPR, and their cognate terms shall be
                  construed accordingly.
                </li>
              </ul>
              <li>
                <strong>Processing of Company Personal Data</strong>
              </li>
              <ul>
                <li> • 2.1 Processor shall:</li>
                <li>
                  {" "}
                  • 2.1.1 comply with all applicable Data Protection Laws in the
                  Processing of Company Personal Data; and
                </li>
                <li>
                  {" "}
                  • 2.1.2 not Process Company Personal Data other than on the
                  relevant Company’s documented instructions.
                </li>
                <li>
                  • 2.2 The Company instructs Processor to process Company
                  Personal Data.
                </li>
              </ul>
              <li>
                <strong>Processor Personnel</strong>
              </li>
              Processor shall take reasonable steps to ensure the reliability of
              any employee, agent, or contractor of any Contracted Processor who
              may have access to the Company Personal Data, ensuring in each
              case that access is strictly limited to those individuals who need
              to know/access the relevant Company Personal Data, as strictly
              necessary for the purposes of the Principal Agreement, and to
              comply with Applicable Laws in the context of that individual’s
              duties to the Contracted Processor, ensuring that all such
              individuals are subject to confidentiality undertakings or
              professional or statutory obligations of confidentiality.
              <li>
                <strong>Security</strong>
              </li>
              <ul>
                <li>
                  4.1 Taking into account the state of the art, the costs of
                  implementation and the nature, scope, context, and purposes of
                  Processing as well as the risk of varying likelihood and
                  severity for the rights and freedoms of natural persons,
                  Processor shall, in relation to the Company Personal Data,
                  implement appropriate technical and organizational measures to
                  ensure a level of security appropriate to that risk,
                  including, as appropriate, the measures referred to in Article
                  32(1) of the GDPR.
                </li>
                <li>
                  4.2 In assessing the appropriate level of security, Processor
                  shall take account in particular of the risks that are
                  presented by Processing, in particular from a Personal Data
                  Breach.
                </li>
              </ul>
              <li>
                <strong>Subprocessing</strong>
              </li>
              Processor shall not appoint (or disclose any Company Personal Data
              to) any Subprocessor unless required or authorized by Wiljekoffie
              B.V.
              <li>
                <strong>Data Subject Rights</strong>
              </li>
              <ul>
                <li>
                  • 6.1 Taking into account the nature of the Processing,
                  Processor shall assist Wiljekoffie B.V. by implementing
                  appropriate technical and organizational measures, insofar as
                  this is possible, for the fulfillment of the Company
                  obligations, as reasonably understood by Wiljekoffie B.V., to
                  respond to requests to exercise Data Subject rights under the
                  Data Protection Laws.
                </li>
                <li>• 6.2 Processor shall:</li>
                <li>
                  • 6.2.1 promptly notify Wiljekoffie B.V. if it receives a
                  request from a Data Subject under any Data Protection Law in
                  respect of Company Personal Data; and
                </li>
                <li>
                  • 6.2.2 ensure that it does not respond to that request except
                  on the documented instructions of Wiljekoffie B.V. or as
                  required by Applicable Laws to which the Processor is subject,
                  in which case Processor shall, to the extent permitted by
                  Applicable Laws, inform Wiljekoffie B.V. of that legal
                  requirement before the Contracted Processor responds to the
                  request.
                </li>
              </ul>
              <li>
                <strong>Personal Data Breach</strong>
              </li>
              <ul>
                <li>
                  • 7.1 Processor shall notify Wiljekoffie B.V. without undue
                  delay upon Processor becoming aware of a Personal Data Breach
                  affecting Company Personal Data, providing Wiljekoffie B.V.
                  with sufficient information to allow Wiljekoffie B.V. to meet
                  any obligations to report or inform Data Subjects of the
                  Personal Data Breach under the Data Protection Laws.
                </li>
                <li>
                  • 7.2 Processor shall cooperate with Wiljekoffie B.V. and take
                  reasonable commercial steps as are directed by Wiljekoffie
                  B.V. to assist in the investigation, mitigation, and
                  remediation of each such Personal Data Breach
                </li>
              </ul>
            </ol>
          </ol>
          </div>
        </Scrollbars>

        <div className="dpa__actions button_col">
          <Button variant="primary" onClick={onAccept}>
            Accept
          </Button>
          <Button variant="text">
            Decline
          </Button>
          <div className="gradient__spacer"></div>
        </div>
      </div>
      <LogoCircles numberOfCircles={5} />
    </>
  );
};

export default DPAModal;
