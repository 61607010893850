import axios, {AxiosError} from 'axios';
import {JwtUtil} from "./util/jwt-util";

const axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JwtUtil.get()}`
    }
});

// Request interceptor
axiosClient.interceptors.request.use(
    (config: any) => {
        const token = JwtUtil.get();
        if (token) {
            console.log('Authorization token found, adding to request header');
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error: AxiosError) => {
        Promise.reject(error)
    }
);

export default axiosClient;
