import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import CurrencyInput from "./CurrencyInput";

type AmountInputProps = {
  amount: string;
  onAmountChange: (value: string) => void;
  onCurrencyChange: (value: ((prevState: string) => string) | string) => void;
  currency: string;
};

const AmountInput: React.FC<AmountInputProps> = ({
  amount,
  onAmountChange,
  onCurrencyChange,
  currency,
}) => (
  <Form.Group as={Row} className="mb-3">
    <Col sm="3">
        <CurrencyInput onCurrencyChange={onCurrencyChange} currency={currency}/>
    </Col>
    <Col sm="9">
    <Form.Label column sm="2">
      Amount
    </Form.Label>
      <Form.Control
        type="number"
        value={amount}
        onChange={(e) => onAmountChange(e.target.value)}
        required
      />
    </Col>
  </Form.Group>
);

export default AmountInput;
