import React, {useEffect, useState} from "react";
import { Spinner} from "react-bootstrap";
import {API_URL} from "../util/constants";
import {JwtUtil} from "../util/jwt-util";
import SuccesfulConnection from "../components/SuccessfulConnection";
import InstallButton from "../components/InstallButton";
import axiosClient from "../AxiosConfig";

interface CallbackProps {
    provider: 'hubspot' | 'mollie';
}

const Callback: React.FC<CallbackProps> = ({provider}) => {
    const [error, setError] = useState<string | null>(getQueryParameter('error_description'));
    const [loading, setLoading] = useState<boolean>(!error);
    const code = getQueryParameter('code');

    useEffect(() => {

        const fetchToken = async () => {
            if (!code) {
                setError('Code is missing.');
                setLoading(false);
                return;
            }

            try {
                const response = await axiosClient.get(`oauth/${provider}/callback?code=${code}`, {withCredentials: true});
                handleResponse(response.data);
            } catch (err) {
                setError(err instanceof Error ? err.message : 'Something went wrong, please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchToken();
        // eslint-disable-next-line
    }, [code, provider]);

    function successRedirect() {
        // Wait 3 seconds before redirecting to the settings page
        setTimeout(() => {
            window.location.href = '/';
        }, 3000);
    }

    const handleResponse = (data: any) => {
        if (data.error) {
            setError(data.error.toString());
        } else if (data.jwt) {
            console.log('checking some values', data.jwt, API_URL);

            JwtUtil.saveJwt(data.jwt);
            successRedirect();

        } else if (data.message) {
            successRedirect();
        } else {
            setError('Unexpected response received.');
        }
    };

    if (loading) {
        return <Spinner/>;
    }

    if (error) {
        return     <>
        <div className="ConnectionStatus">
            <SuccesfulConnection message="Failed" success={false} /><p style={{ color: '#fff' }}> We ran into an issue</p>
            <InstallButton oAuthProvider={provider} text={'Try again'}/>
        </div>
        </>

    }

    return <>
    <div className="ConnectionStatus">
    <SuccesfulConnection message="Connected" success={true} /><p style={{ color: '#fff' }}> You will be redirected to the settings page.</p>
    </div></>
}

const getQueryParameter = (param: string): string | null => {
    return new URLSearchParams(window.location.search).get(param);
};

export default Callback;
