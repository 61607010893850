import React from "react";
import { Form} from "react-bootstrap";

// We currently support the following currencies EUR, GBP, SEK, NOK, PLN, CHF, USD, DKK
const currencyOptions = [
    "EUR", "GBP", "SEK", "NOK", "PLN", "CHF", "USD", "DKK"
]

function CurrencyInput(props: {
    onCurrencyChange: (value: (((prevState: string) => string) | string)) => void,
    currency: string
}) {

    return<>
        <Form.Label column sm="2">
            Currency
        </Form.Label>
        <Form.Control as="select" value={props.currency} onChange={(e) => props.onCurrencyChange(e.target.value)}>
            {currencyOptions.map((currency) => <option key={currency}>{currency}</option>)}
        </Form.Control>
    </>

}

export default CurrencyInput;