import React from "react";
import LogoCircles from "./LogoCircles";
import mollieLogo from "../Mollie.svg";

interface SuccesfulConnectionProps{
    message?:string;
    success:boolean;
    element?: JSX.Element;
}

function SuccesfulConnection(props: SuccesfulConnectionProps){

    function successSVG(){
        return             <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="12" fill="#60C78C" />
        <g clip-path="url(#clip0_196_14)">
            <path
                d="M9.18623 17C8.94439 17 8.75629 16.896 8.59506 16.7659L5.21502 13.2399C4.91944 12.9277 4.91944 12.4335 5.24189 12.1474C5.56435 11.8353 6.07491 11.8613 6.39736 12.1734L9.2131 15.1012L16.6296 8.20809C16.9521 7.92197 17.4626 7.92197 17.7582 8.2341C18.0538 8.54624 18.0538 9.04046 17.7313 9.32659L9.75053 16.7659C9.5893 16.922 9.4012 17 9.18623 17Z"
                fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_196_14">
                <rect
                    width="13"
                    height="9"
                    fill="white"
                    transform="translate(5 8)" />
            </clipPath>
        </defs>
    </svg>
    }
    function failedSVG(){
        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#FF4D4D"/>
        <path d="M7.20681 16.7932C6.93106 16.5174 6.93106 16.0704 7.20681 15.7946L15.7946 7.20681C16.0703 6.93106 16.5174 6.93106 16.7932 7.20681C17.0689 7.48256 17.0689 7.92964 16.7932 8.20539L8.20539 16.7932C7.92964 17.0689 7.48256 17.0689 7.20681 16.7932Z" fill="white"/>
        <path d="M16.7932 16.7932C16.5174 17.0689 16.0704 17.0689 15.7946 16.7932L7.20683 8.2054C6.93108 7.92965 6.93108 7.48257 7.20683 7.20682C7.48258 6.93107 7.92966 6.93107 8.20541 7.20682L16.7932 15.7946C17.0689 16.0704 17.0689 16.5174 16.7932 16.7932Z" fill="white"/>
        </svg>
        
    }

    return <><div className="logo__wrapper">
        <img src={mollieLogo} className="Mollie-logo" alt="logo" />
        <LogoCircles numberOfCircles={5} />
    </div><div className="connection-status">
            { props.success ? 
            successSVG()
            :
            failedSVG()
            }
            <p>{props?.element}{props.message}</p>
        </div></>
  
}

export default SuccesfulConnection;