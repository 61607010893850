import React from 'react';
import { Form, Col } from 'react-bootstrap';

type TotalDisplayProps = {
    total: string
};

const TotalDisplay: React.FC<TotalDisplayProps> = ({ total }) => (
    <Form.Group as={Col} className="mb-3">
        <Form.Label column sm="12">Total</Form.Label>
        <Col sm="12">
            <Form.Control type="text" value={total} readOnly />
        </Col>
    </Form.Group>
);

export default TotalDisplay;
