import React from 'react';
import {Form, Row, Col} from 'react-bootstrap';

type TaxInputProps = {
    taxPercentage: string,
    customTaxValue: string,
    onTaxChange: (value: string) => void,
    onCustomTaxChange: (value: string) => void
};

const taxPercentageOptions = [
    "0", "6", "9", "12", "19", "20", "21", "23", "25"
];
const TaxInput: React.FC<TaxInputProps> = ({taxPercentage, customTaxValue, onTaxChange, onCustomTaxChange}) => (
    <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="12">Tax {taxPercentage === 'custom' ? ' amount' : '%'}</Form.Label>
        <Col sm={taxPercentage === 'custom' ? "9" : "12"}>
            <Form.Control as="select" value={taxPercentage} onChange={(e) => onTaxChange(e.target.value)}>
                {taxPercentageOptions.map((tax) => <option key={tax}>{tax}</option>)}
                <option value="custom">Custom (amount)</option>
            </Form.Control>
        </Col>
        {taxPercentage === 'custom' && (
            <Col sm="3">
                <Form.Control type="number" value={customTaxValue} placeholder="e.g. 7"
                              onChange={(e) => onCustomTaxChange(e.target.value)}/>
            </Col>
        )}
    </Form.Group>
);

export default TaxInput;
