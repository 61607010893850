import { useEffect } from 'react';
import { JwtUtil } from '../util/jwt-util';

function useJwtToken() {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        if (token) {
            JwtUtil.saveJwt(token);
        }
    }, []);
}

export default useJwtToken;
