import React, {useState, useEffect} from 'react';
import {Spinner} from 'react-bootstrap';
import PaymentLinkForm from "../components/PaymentLinkForm";
import CopyLink from "../components/CopyLink";
import Header from '../components/Header';
import axiosClient from "../AxiosConfig";
import useJwtToken from "../hooks/useJwtToken";
import useQueryParams from "../hooks/useQueryParams";

function CardIFrame() {

    const [amount, setAmount] = useState('');
    const [taxPercentage, setTaxPercentage] = useState('0');
    const [customTaxValue, setCustomTaxValue] = useState('');
    const [total, setTotal] = useState('0');
    const [loading, setLoading] = useState(false);
    const [paymentLink, setPaymentLink] = useState('');
    const [portalId, setPortalId] = useState('');
    const [dealId, setDealId] = useState('');
    const [currency, setCurrency] = useState('EUR');

    const queryParams = useQueryParams();

    useJwtToken();

    useEffect(() => {
        console.log('Tax percentage changed', taxPercentage);
        console.log('customTaxValue changed', customTaxValue);
        if (taxPercentage !== 'custom') {
            setCustomTaxValue('');
        }
        // If the custom tax amount value is filled, use it to calculate the total
        const taxAmount = customTaxValue !== '' ? parseFloat(customTaxValue) : parseFloat(amount) * (parseFloat(taxPercentage) / 100);
        setTotal((parseFloat(amount) + taxAmount).toFixed(2));
    }, [amount, taxPercentage, customTaxValue]);

    useEffect(() => {
        if (queryParams.get('amount')) setAmount(queryParams.get('amount') || '');
        if (queryParams.get('portalId')) setPortalId(queryParams.get('portalId') || '');
        if (queryParams.get('dealId')) setDealId(queryParams.get('dealId') || '');
        if (queryParams.get('currency')) setCurrency(queryParams.get('currency') || '');
    }, [queryParams]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axiosClient.post(`payment-links`, {
                amount: total,
                portalId: portalId,
                dealId: dealId,
                currency: currency
            }, {withCredentials: true});
            // Set the payment link in the state
            setPaymentLink(response.data.link);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    return (
        <>
            <Header/>
            <header className="App-header Payment-header">
                {paymentLink
                    ? <CopyLink link={paymentLink}/>
                    : loading
                        ? <Spinner/>
                        : <PaymentLinkForm
                            onSubmit={handleSubmit}
                            amount={amount}
                            onAmountChange={setAmount}
                            taxPercentage={taxPercentage}
                            customTaxValue={customTaxValue}
                            onTaxChange={setTaxPercentage}
                            onCustomTaxChange={setCustomTaxValue}
                            total={total}
                            currency={currency}
                            onCurrencyChange={setCurrency}/>}
            </header>
        </>
    );
}

export default CardIFrame;
