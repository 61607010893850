import React from "react";
import {useParams} from "react-router-dom";
import CopyLink from "../components/CopyLink";
import Header from "../components/Header";

function CopyLinkIframe() {

    const {id} = useParams<{ id: string }>();
    const link = `${window.location.protocol}//${window.location.hostname}/payment-links/${id}`;

    return (
        <><Header />
                <header className="App-header Payment-header">
            <CopyLink link={link} />
            </header>
        </>
    );
}

export default CopyLinkIframe;