import React from "react";
import WjkLogo from "../Logo WJK.svg"
import '../css/footer.css';

const FooterLogo = <a href={"https://wiljekoffie.com"}><img src={WjkLogo} className="Footer-logo" alt="logo"/></a>;
const Footer: React.FC = () => {

    return <footer> Powered by {FooterLogo}</footer>;
}


export default Footer;
