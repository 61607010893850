import React from 'react';

interface DivGeneratorProps {
  numberOfCircles: number;
}

const LogoCircles: React.FC<DivGeneratorProps> = ({ numberOfCircles }) => {
  const circles = [];

  for (let i = 0; i < numberOfCircles; i++) {
    const width = 226 + (i * 180); // Increase width by 180px on each iteration
    const opacity = 0.8 - (i * 0.2);
    const circleStyle = {
      width: `${width}px`,
      height: `${width}px`, // Set the height as desired
      opacity: `${opacity}`,
      animationDelay: `${i * 0.2}s`, // Delay the animation for each circle

    };

    circles.push(<div key={i} className='logo__circle' style={circleStyle}></div>);
  }

  return <div>{circles}</div>;
};

export default LogoCircles;
