import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import ProfileSelectInput from "./ProfileSelectInput";
import '../css/forms.css'
import axiosClient from "../AxiosConfig";

interface IMollieSettingsTabProps {
    setHasMollieAccount: (hasMollieAccount: boolean) => void;
}

function MollieSettingsTab({ setHasMollieAccount }: IMollieSettingsTabProps) {
    const [profileId, setProfileId] = useState<string | null>(null);
    const [accountId, setAccountId] = useState<string | null>(null);

    useEffect(() => {
        async function getMollieAccount() {
            try {
                const result = await axiosClient.get(`/mollie-accounts`, { withCredentials: true });
                if (result.data) {
                    setProfileId(result.data.websiteProfileId || null);
                    setAccountId(result.data.id || null);
                }
            } catch (e) {
                console.log(e);
            }
        }

        getMollieAccount();
    }, []);

    const updateSettings = async () => {
        if (!accountId) return;

        try {
            await axiosClient.patch(`/mollie-accounts/${accountId}`, {
                profileId: profileId
            });
            alert('Settings saved');
        } catch (e) {
            console.log(e);
            alert('Something went wrong');
        }
    };

    const disconnectMollie = async () => {
        try {
            await axiosClient.delete(`mollie-accounts`);
            alert('Mollie account disconnected');
            setHasMollieAccount(false);
        } catch (e) {
            console.log(e);
            alert('Something went wrong');
        }
    };

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                updateSettings();
            }}
            className="settings-form"
        >
            <div className="form__title">
                <div className="form__title-line"></div>
                <p>Mollie Settings</p>
                <div className="form__title-line"></div>
            </div>
            <FormGroup>
                {/* <OrganizationInput /> */}
            </FormGroup>
            <FormGroup className="formgroup">
                <ProfileSelectInput profileId={profileId} setProfileId={setProfileId} setHasMollieAccount={setHasMollieAccount}/>
            </FormGroup>

            <Row className="form-actions">
                <Col>
                    <FormGroup>
                        <Button variant="primary" style={{ padding: '10px 20px' }} type="submit">
                            Save
                        </Button>
                    </FormGroup>
                    <FormGroup>
                        <Button variant="text" className="btn-gray-text" style={{ padding: '10px 20px' }} onClick={disconnectMollie}>
                            Disconnect Mollie
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
}

export default MollieSettingsTab;
