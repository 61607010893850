export enum PaymentStatus {
    Generated = "generated",
    Paid = "paid",
    Failed = "failed",
    Refunded = "refunded",
    Error = "error"
}

export interface IPaymentLink {
    paidAt: Date;
    status: PaymentStatus;
    id: number;
    uuid: string;
    amount: number;
    dealId: number;
    link: string;
    molliePaymentId: string;
    currency: string;
    checkoutUrl?: string;
    website?: string;
}