import React from "react";
import SuccesfulConnection from "./SuccessfulConnection";
import {Button} from "react-bootstrap";

interface PaymentFormProps {
    amount: number;
    currencyCode: string;
    website?: string;
}

function PaymentForm(props: PaymentFormProps) {

    function getCurrencySymbol(currencyCode: string) {
        try {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currencyCode,
            }).formatToParts(1)[0].value;
        } catch (error) {
            console.error(`Unable to get currency symbol for ${currencyCode}. Error: ${error}`);
            return currencyCode; // Return the code itself as a fallback
        }
    }

    const currencyCode = getCurrencySymbol(props.currencyCode);

    return <div className="App-header payment-header">
        <SuccesfulConnection message="Payment completed" success={true}/>
        <div className="payment-table__container">
            <div className="payment__table">
                <div className="pt__row">
                    <span className="pt__col">Website</span>
                    <span className="pt__col"><a href={props.website}
                                                 className="link-primary">{props.website}</a></span>
                </div>
                <div className="pt__line"></div>
                <div className="pt__row">
                    <span className="pt__col pt__col-bold">Amount Paid</span>
                    <span className="pt__col pt__col-bold">{currencyCode}{props.amount}</span>
                </div>
                <div className="pt__line-colored"></div>
            </div>
            <div className="payment__actions button_col">
                <Button variant="primary" onClick={
                    // eslint-disable-next-line no-restricted-globals
                    () => print()}>
                    Print
                </Button>
            </div>
        </div>
    </div>
}

export default PaymentForm