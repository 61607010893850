import React from "react";
import {Button, Form} from "react-bootstrap";
import AmountInput from "./AmountInput";
import TaxInput from "./TaxInput";
import TotalDisplay from "./TotalDisplay";

function PaymentLinkForm(props: {
    onSubmit: (e: React.FormEvent) => void,
    amount: string,
    onAmountChange: (value: (((prevState: string) => string) | string)) => void,
    taxPercentage: string,
    customTaxValue: string,
    onTaxChange: (value: (((prevState: string) => string) | string)) => void,
    onCustomTaxChange: (value: (((prevState: string) => string) | string)) => void,
    onCurrencyChange: (value: (((prevState: string) => string) | string)) => void,
    currency: string,
    total: string
}) {
    return <>
        <h4>Create payment link</h4>
        <Form className="PaymentLinkForm" onSubmit={props.onSubmit}>
            <AmountInput amount={props.amount} onAmountChange={props.onAmountChange} currency={props.currency}
                         onCurrencyChange={props.onCurrencyChange}/>
            <TaxInput taxPercentage={props.taxPercentage} customTaxValue={props.customTaxValue}
                      onTaxChange={props.onTaxChange} onCustomTaxChange={props.onCustomTaxChange}/>
            <TotalDisplay total={props.total}/>
            <Button type="submit">Create Payment Link</Button>
        </Form>
    </>;
}

export default PaymentLinkForm;