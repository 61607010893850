import { useEffect, useState } from 'react';

function useQueryParams() {
    const [queryParams, setQueryParams] = useState(new URLSearchParams(window.location.search));

    useEffect(() => {
        setQueryParams(new URLSearchParams(window.location.search));
    }, []);

    return queryParams;
}

export default useQueryParams;
