import React from "react";
import InstallButton from "../components/InstallButton";
import MollieSettingsTab from "../components/MollieSettingsTab";
import logo from "../hubspot-logo.svg";
import mlogo from "../Mollie-M.svg";
import LogoCircles from "../components/LogoCircles";
import Header from "../components/Header";
import SuccesfulConnection from "../components/SuccessfulConnection";
import {JwtUtil} from "../util/jwt-util";


interface IMollieOAuthProps {
  hasMollieAccount: boolean;
  setHasMollieAccount: (hasMollieAccount: boolean) => void;
}

export interface Profile {
  profileId: string;
  name: string;
  status: string;
}

function MollieOAuth(mollieOAuthProps: IMollieOAuthProps) {
    const portalId = JwtUtil.getPortalId();
  return (
    <>
      {!mollieOAuthProps.hasMollieAccount && <Header />}
      <header className="App-header">
        {mollieOAuthProps.hasMollieAccount ? (
          <>
            <SuccesfulConnection message="Connected" success={true}  />
              <a className="link-primary mollie-link"
                 href={`https://app-eu1.hubspot.com/contacts/${portalId}/objects/0-3/views/all/list`}
                 target="_blank" rel="noreferrer"> Start collecting payments </a>
            <MollieSettingsTab
            setHasMollieAccount={mollieOAuthProps.setHasMollieAccount}/>
          </>
        ) : (
          <>
            <div className="logo__wrapper logo__flex">
              <img src={mlogo} className="App-logo" alt="Mollie logo" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="22"
                viewBox="0 0 26 22"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.2929 12.2929C20.6834 11.9024 21.3166 11.9024 21.7071 12.2929L25.7071 16.2929C26.0976 16.6834 26.0976 17.3166 25.7071 17.7071L21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071C19.9024 21.3166 19.9024 20.6834 20.2929 20.2929L23.5858 17L20.2929 13.7071C19.9024 13.3166 19.9024 12.6834 20.2929 12.2929Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 17C0 16.4477 0.447715 16 1 16H25C25.5523 16 26 16.4477 26 17C26 17.5523 25.5523 18 25 18H1C0.447715 18 0 17.5523 0 17Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.70711 0.292893C6.09763 0.683417 6.09763 1.31658 5.70711 1.70711L2.41421 5L5.70711 8.29289C6.09763 8.68342 6.09763 9.31658 5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 5C0 4.44772 0.447715 4 1 4H25C25.5523 4 26 4.44772 26 5C26 5.55228 25.5523 6 25 6H1C0.447715 6 0 5.55228 0 5Z"
                  fill="white"
                />
              </svg>
              <img src={logo} className="App-logo" alt="HubSpot logo" />
              <LogoCircles numberOfCircles={5} />
            </div>
            <p className="App-header__text">
              Connecting <b>Mollie</b> to <b>HubSpot</b>
            </p>
            <div className="App-header__actions button_col">
              <InstallButton oAuthProvider={"mollie"} />
            </div>
          </>
        )}
      </header>
    </>
  );
}

export default MollieOAuth;
