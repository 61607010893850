import {useParams} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import axiosClient from "../AxiosConfig";
import SuccesfulConnection from "../components/SuccessfulConnection";
import {IPaymentLink} from "../model/IPaymentLink";
import useJwtToken from "../hooks/useJwtToken";

export function RefundIFrame() {
    const {id} = useParams();
    const [paymentLink, setPaymentLink] = useState<IPaymentLink | null>(null);
    const [amount, setAmount] = useState(0);
    const [reason, setReason] = useState("");
    const [error, setError] = useState<string>("");

    useJwtToken();

    useEffect(() => {
        const fetchPaymentLink = async () => {
            try {
                const response = await axiosClient.get<IPaymentLink>(`/payment-links/${id}`);
                if (response.status === 200) {
                    setPaymentLink(response.data);
                    setAmount(response.data?.amount);
                }
            } catch (error) {
                console.error(error);
                setError('Error fetching payment link')
            }
        }

        fetchPaymentLink();
    }, [id]);

    async function handleSubmit(event: React.FormEvent) {
        console.log('On submit triggered')
        event.preventDefault();
        //Assert we have the payment link
        if (!paymentLink) {
            setError("No payment link found");
            return;
        }

        if (amount > (Number(paymentLink.amount))) {
            setError("Refund amount is too high!");
            return;
        }

        try {
            console.log('Sending request')
            await axiosClient.post(`refunds`, {
                paymentId: id,
                amount: amount,
                reason: reason
            },
                {withCredentials: true});
            console.log('Request sent')
            window.location.href = '/refund-success';
        } catch (error) {
            console.log('Error refunding payment')
            setError('Error refunding payment')
            console.error(error);
        }

    }

    if (error) {
        return <div className="App-header payment-header">
            <SuccesfulConnection message={error ? error : 'Payment link not found'} success={false}/>
            <Button onClick={() => window.location.reload()}>Back</Button>
        </div>
    }

    function handleAmountChange(event: React.ChangeEvent<HTMLInputElement>) {
        const amount = parseFloat(event.target.value);
        if (amount < 0) {
            return;
        }
        setAmount(amount);
    }

    return <>
        <h4>Refund payment</h4>
        <Form className={'PaymentLinkForm'} onSubmit={handleSubmit}>
            <Form.Group>
                <Form.Label>Refund amount</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Enter refund amount"
                    value={amount}
                    onChange={handleAmountChange}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Refund reason</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter refund reason"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                />
            </Form.Group>
            <Button type="submit">Submit</Button>
        </Form>
    </>;
}