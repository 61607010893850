import React, { useEffect, useState } from "react";
import logo from "../hubspot-logo.svg";

import InstallButton from "../components/InstallButton";
import DPASection from "../components/DPASection";
import LogoCircles from "../components/LogoCircles";
import Footer from "../components/Footer";
import { Button } from "react-bootstrap";
import Header from "../components/Header";

function Home() {
  const [showDPA, setShowDPA] = useState<boolean>(false);

  function handleAcceptDPA() {
    localStorage.setItem("acceptedDPA", "true");
    setShowDPA(false);
  }

  useEffect(() => {
    const acceptedDPA = localStorage.getItem("acceptedDPA");
    if (!acceptedDPA) {
      setShowDPA(true);
    }
  }, []);

  return (
    <>
      {/* <DPAModal show={showDPA} onAccept={handleAcceptDPA}/> */}
      { showDPA && <Header/>}
      <header className="App-header">
        {showDPA ? (
          <DPASection show={showDPA} onAccept={handleAcceptDPA}/>
        ) : (
          <>
            <div className="logo__wrapper">
              <img src={logo} className="App-logo" alt="logo" />
              <LogoCircles numberOfCircles={5} />
            </div>
            <p className="App-header__text">
              Connecting <b>HubSpot</b>
            </p>
            <div className="App-header__actions button_col">
              <InstallButton oAuthProvider={"hubspot"} />
              <Button variant="text btn-white-text">
                Cancel
              </Button>
            </div>
          </>
        )}
      </header>
      <Footer />
    </>
  );
}

export default Home;
